import React from "react"
import { graphql, Link } from "gatsby"
import { RawLayout } from "../../../components/layouts"
import SEO from "../../../components/seo"
import Row from "../../../components/row"
import Column from "../../../components/column"
import Container from "../../../components/container"
import Banner from "../../../components/banner"
import HackathonCard from "../../../components/hackathonCard"
import { Hackathon } from "../../../types/Hackathon"
import { banner } from "./events.module.scss"
import PartnerChip from "../../../components/PartnerChip"
import cx from "classnames"

interface EventsPageProps {
  data: {
    upcoming?: {
      nodes: Array<Hackathon>
    }
    past: {
      nodes: Array<Hackathon>
    }
  }
  pageContext : {
    upcoming: boolean
  }
}

const EventsPage: React.FC<EventsPageProps> = ({ data, pageContext  }) => {
  // Get every season slug from both upcoming and past events
  const seasons = (pageContext.upcoming ? [...data.past.nodes, ...data.upcoming.nodes] : data.past.nodes)
    .map(event => event.season) // Extract seasons slugs from the array
    .filter((value, index, self) => self.indexOf(value) === index) // Filter out duplicates
    .filter(value => value !== null) // Prevents this from breaking with unpublished data
    .map(slug => {
      // Convert slugs to tuples of season name and slugs
      const season = slug.substr(0, 1) // Season identifier is the first character
      const year = slug.substr(1) // Year is th remaining characters

      switch (season) {
        case "s": // s = Spring
          return [`Spring ${year}`, slug]

        case "m": // m = Summer
          return [`Summer ${year}`, slug]

        case "a": // a = Autumn
          return [`Autumn ${year}`, slug]
      }
    })
    .map(([season, slug]) => [
      // Convert list of season/slug tuples to list of season/event tuples
      season,
      {
        past: data.past.nodes.filter(({ season }) => season === slug), // Assign past events from the specific season
        upcoming: pageContext.upcoming ? data.upcoming.nodes.filter(({ season }) => season === slug) : [], // Assign upcoming events from the specific season
      },
    ]) as [string, { upcoming: Hackathon[]; past: Hackathon[] }][]

  // Get upcoming events only from the seasons list
  const upcoming: [string, Hackathon[]][] = seasons
    .filter(([_, { upcoming }]) => upcoming.length > 0)
    .map(([season, { upcoming }]) => [season, upcoming])

  // Get past events only from the seasons list
  const past: [string, Hackathon[]][] = seasons
    .filter(([_, { past }]) => past.length > 0)
    .map(([season, { past }]) => [season, past])

  return (
    <RawLayout>
      <SEO title="Events" />
      <Banner className={banner}>
        <Column>
          <h1>UK Hackathon Calendar</h1>
          <h2>Upcoming and Past Hackathons</h2>
          <p>
            Here's a collection of the UK student-run hackathons we know about.
            While it's our goal to list every hackathon accurately, we can only
            guarantee information for events marked <PartnerChip />
          </p>
          <p>
            If you'd like to work with us, have your event listed, or its
            details updated, please <Link to="/contact">get in touch</Link>!
          </p>
        </Column>
      </Banner>
      {pageContext.upcoming && upcoming.length && (
        <div id="features-wrapper">
          <Container>
            <h1>Upcoming Events</h1>
            {upcoming.map(([season, hackathons]) => (
              <div key={"upcoming-" + hackathons[0].season} className={cx(["season", "upcoming", hackathons[0].season])}>
                <h2 className="season-name">{season}</h2>
                <Row>
                  {hackathons.map((hackathon, j) => (
                    <HackathonCard
                      key={"upcoming-" + hackathon.season + "-" + j}
                      {...hackathon}
                    />
                  ))}
                </Row>
                <br />
              </div>
            ))}
          </Container>
        </div>
      )}
      {past.length && (
        <div id="features-wrapper">
          <Container>
            <h1>Past Events</h1>
            {past.map(([season, hackathons]) => (
              <div key={"past-" + hackathons[0].season} className={cx(["season", "past", hackathons[0].season])}>
                <h2 className="season-name">{season}</h2>
                <Row>
                  {hackathons.map((hackathon, j) => (
                    <HackathonCard
                      key={"past-" + hackathon.season + "-" + j}
                      {...hackathon}
                    />
                  ))}
                </Row>
                <br />
              </div>
            ))}
          </Container>
        </div>
      )}
    </RawLayout>
  )
}

export default EventsPage

export const EventsPageQuery = graphql`
  query EventsPageQuery($today: Date!, $yearAgo: Date!, $upcoming: Boolean!) {
    upcoming: allContentfulHackathon(
      filter: { endDate: { gte: $today } }
      sort: { fields: startDate, order: ASC }
    ) @include(if: $upcoming) {
      nodes {
        ...HackathonFields
      }
    }
    past: allContentfulHackathon(
      filter: { endDate: { lt: $today }, startDate: { gte: $yearAgo } }
      sort: { fields: startDate, order: DESC }
    ) {
      nodes {
        ...HackathonFields
      }
    }
  }
`
