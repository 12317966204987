import React from "react"
import { chip, chipContent, chipItalic } from "./PartnerChip.module.scss"

const PartnerChip = () => {
  return (
    <div className={chip}>
      <div className={chipContent}>
        <span className={chipItalic}>Hackathons</span>&nbsp;UK – Official Partner
      </div>
    </div>
  )
}

export default PartnerChip
