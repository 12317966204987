import Img, { FluidObject } from "gatsby-image"
import React from "react"
import ReactGA from "react-ga"
import Column from "./column"
import cx from "classnames"
import PartnerChip from "./PartnerChip"
import { Hackathon } from "../types/Hackathon"
import { DateTime } from "luxon"
import dateString from "../utils/hackathonDurationString"
import OnlineChip from "./OnlineChip"
import U18Chip from "./U18Chip"
import HybridChip from "./HybridChip"
import Button from "./button"
import { graphql, useStaticQuery } from "gatsby"

const HackathonCard: React.FC<Hackathon> = ({
  name,
  headerImage,
  url,
  location,
  digital,
  partnered,
  allAges,
  ...hackathon
}) => {
  const startDate = DateTime.fromISO(hackathon.startDate)
  const endDate = DateTime.fromISO(hackathon.endDate)

  const placeHolderImageData = useStaticQuery<{ file: { 
    childImageSharp: {
      fluid: FluidObject,
    },
  }}>(graphql`
    query PlaceHolderImageQuery {
      file(relativePath: {eq: "placeholder-event.png"}) {
        childImageSharp {
          fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Column large={4} medium={6} small={12}>
      <section className={cx("box", "feature")}>
        <ReactGA.OutboundLink
          eventLabel="hackathon_card_image"
          to={url}
          target="_blank"
          rel="noopener noreferrer"
          className={cx("image", "featured")}
          title={name}
        >
          {headerImage && 
          <Img
            fluid={headerImage.fluid}
            alt={`${name} banner`}
          />}
          {!headerImage && 
          <Img
            fluid={placeHolderImageData.file.childImageSharp.fluid}
            alt="Placeholder Banner"
          />}
        </ReactGA.OutboundLink>
        <div className="inner" style={{ paddingTop: "2em" }}>
          <h2 style={{ marginBottom: ".2em" }}>{name}</h2>
          <p>{partnered ? <PartnerChip /> : <span>&nbsp;</span>}</p>
          <p>{dateString(startDate, endDate)}</p>
          <p>{location}</p>
          <p>
            {digital === "Yes" && <OnlineChip />}
            {digital === "Hybrid" && <HybridChip />}
            {allAges && <U18Chip />}
          </p>
          <br />
          <Button
            eventLabel="hackathon_card_button"
            iconPos="right"
            to={url}
            as="a"
            small
          >
            Learn More
          </Button>
        </div>
      </section>
    </Column>
        )
      }

export default HackathonCard
